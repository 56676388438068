<template>
    <vue-hcaptcha :sitekey="siteKey" @verify="onVerify" @error="onError" @challenge-expired="onChallengeExpired" @expired="onExpired"></vue-hcaptcha>
  </template>
  
  <script setup>
  import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
  
  const emits = defineEmits(['onVerify', 'onError', 'onChallengeExpired', 'onExpired']);
  const props = defineProps({
    siteKey: {
      type: String,
      required: true,
    },
  });

  const onVerify = (tokenStr, ekey) => {
    emits('onVerify', tokenStr, ekey);
  };
  
  const onError = (err) => {
    emits('onError', err);
  };
  
  const onChallengeExpired = () => {
    emits('onChallengeExpired');
  };
  
  const onExpired = () => {
    emits('onExpired');
  };
  
  </script>
  